import Vue from "vue";
import Vuex from "vuex";
import { i18n } from "@/utils/i18n";

import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

const ls = new SecureLS({ isCompression: false });

import app from "../main";
import menu from "./modules/menu";
import enums from "./modules/enums";
import MaestroEnums from "./modules/maestro/enums";

/************** Module  *****************/
import user from "./modules/user";
import webmasters from "./modules/webmasters";
import clients from "./modules/clients";
import client_settings from "./modules/client_settings";
import users from "./modules/users";
import countries from "./modules/countries";
import locations from "./modules/locations";
import custom_field_types from "./modules/custom/field_types";
import custom_field_groups from "./modules/custom/field_groups";
import custom_fields from "./modules/custom/fields";
import custom_files from "./modules/custom/files";
import custom_calculators from "./modules/custom/calculators";
import custom_form_models from "./modules/custom/form_models";
import contacts from "./modules/contacts";
import accounting_invoices from "./modules/accounting/invoices";
import accounting_invoice from "./modules/accounting/invoice";
import accounting_payments from "./modules/accounting/payments/index";
import accounting_taxes from "./modules/accounting/taxes";
import accounting_credits from "./modules/accounting/credits";
import accounting_commissions from "./modules/accounting/commissions";
import accounting_commission_payments from "./modules/accounting/commission_payments";
import accounting_commission_rules from "./modules/accounting/commission_rules";
import accounting_currency_rates from "./modules/accounting/currency_rates";

import inventory_price_types from "./modules/inventory/price/types";
import inventory_product_var_option_types from "./modules/inventory/product/var/option/types";
import inventory_product_types from "./modules/inventory/product/types";
import inventory_products from "./modules/inventory/products";
import inventory_product from "./modules/inventory/product";
import inventory_products_order_advisors from "./modules/inventory/products/order_advisors";
import inventory_product_re_packs from "./modules/inventory/product/re_packs";
import inventory_product_pictures from "./modules/inventory/product/pictures";
import inventory_product_categories from "./modules/inventory/product/categories";
import taxons from "./modules/taxons";
import templates from "./modules/templates";
import logistics from "./modules/logistics";
import i18n_docs from "./modules/i18n_docs";

import dashboards from "./modules/dashboards";
import widgets from "./modules/widgets";

/****** Karavan ***********/
import karavan_products from "./modules/karavan/products";
import karavan_stores from "./modules/karavan/stores";
import karavan_shipment_services from "./modules/karavan/shipment_services";
import karavan_orders from "./modules/karavan/orders";

/****** Hive  *******/
import hive_jobs from "./modules/hive/jobs";
import hive_jobs_services from "./modules/hive/jobs/services";
import hive_job_templates from "./modules/hive/job_templates";
import hive_projects from "./modules/hive/projects";
import hive_work_orders from "./modules/hive/work_orders";
import hive_b2b_users from "./modules/hive/b2b_users";
import hive_prepress_files from "./modules/hive/prepress/files";
import hive_prepress_layouts from "./modules/hive/prepress/layouts";

/****** /Hive  *******/

/****** Marketing Rewards  *******/
import marketing_rewards from "./modules/marketing/rewards";
/****** /Marketing Rewards  *******/

/********** Client/Custom/Lamcom ***********/
import client_custom_lamcom_hive_projects from "./modules/client/custom/lamcom/hive/projects";
import client_custom_lamcom_hive_layouts from "./modules/client/custom/lamcom/hive/layouts";
import client_custom_lamcom_hive_steps from "./modules/client/custom/lamcom/hive/steps";
import client_custom_lamcom_hive_work_orders_dashboard_widget
  from "./modules/client/custom/lamcom/hive/work_orders/dashboard_widget";
import client_custom_lamcom_hive_work_orders from "./modules/client/custom/lamcom/hive/work_orders";
import client_custom_lamcom_hive_dashboards_widgets from "./modules/client/custom/lamcom/hive/dashboards/widgets";
import client_custom_lamcom_accounting from "./modules/client/custom/lamcom/accounting";
import client_custom_lamcom_hive_jobs from "./modules/client/custom/lamcom/hive/jobs";
import client_custom_lamcom_reports from "./modules/client/custom/lamcom/reports";

import pigeon_notifications from "./modules/pigeon/notifications";
import tpa from "./modules/tpa";

import { securedAxios } from "@/utils/securedAxios";

//*** For Prepress
import maestro from "@/store/modules/prepress/maestro";
import job from "@/store/modules/prepress/job";
import printServerProcesses from "@/store/modules/prepress/printServerProcesses";

import lamcom_file_groups from "@/store/modules/lamcom/file_groups";
import lamcom_layouts from "@/store/modules/lamcom/layouts";
import lamcom_files from "@/store/modules/lamcom/files";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    listPageConfig: {
      page: 1,
      perPage: 100, // This Should Be Dynamical
      pageSizes: [100, 250, 500, 1000, 5000]
    },
    UIConfig: {
      afterCreateRedirect: true,
      afterUpdateRedirect: true,
      afterErrorRedirect: false,
      buttons: {
        backButtonEnabled: true
      },
      shipmentProductSearch: {
        auto_id: true,
        product_name: true,
        locations: true,
        custom_measurements: true,
        sold_as_one: true,
        description: true,
        taxable: false,
        status: true
      },
      inventory: {
        products: {
          is_location_deletable: false
        }
      },
      hive: {
        jobs: {
          base_item: "material"
        }
      }
    },
    notificationConfig: {
      duration: 1000,
      success_duration: 1000,
      error_duration: 5000,
      permanent: false
    },
    tpaNotificationConfig: {
      duration: 1000,
      permanent: false
    },
    languageList: [
      {
        name: "en",
        id: "en",
        text: i18n.t("languages.en"),
        label: i18n.t("languages.en")
      },
      {
        name: "fr",
        id: "fr",
        text: i18n.t("languages.fr"),
        label: i18n.t("languages.fr")
      },
      {
        name: "es",
        id: "es",
        text: i18n.t("languages.es"),
        label: i18n.t("languages.es")
      }
    ]
  },
  getters: {
    getShipmentProductSearchConfig(state) {
      return state.UIConfig.shipmentProductSearch;
    },
    afterCreateRedirect: state => state.UIConfig.afterCreateRedirect,
    afterUpdateRedirect: state => state.UIConfig.afterUpdateRedirect,
    afterErrorRedirect: state => state.UIConfig.afterErrorRedirect,
    hiveJobBaseItem: state => state.UIConfig.hive.jobs.base_item,
    languageList: state => state.languageList
  },
  mutations: {
    changeLang(state, payload) {
      app.$i18n.locale = payload;
      localStorage.setItem("currentLanguage", payload);
    }
  },
  actions: {
    setLang({ commit }, payload) {
      commit("changeLang", payload);
    },
    // endpoint : String => /clients/
    fetch({}, { endpoint, params }) {
      return securedAxios
        .get(endpoint, params)
        .then(response => {
          return response.data;
        })
        .catch(error => {
          return error;
        });
    },
    // endpoint : String => /clients/, id : String => 5cd8284a30b7210004a0b32f
    fetchOne({}, { endpoint, id, postFix, params }) {
      if (endpoint?.charAt(endpoint?.length - 1) !== "/") endpoint += "/";
      postFix = postFix == undefined ? "" : postFix;
      return securedAxios
        .get(`${endpoint}${id}${postFix}`, { params: params })
        .then(response => {
          let userData = {};
          userData = {
            ...response.data
          };
          // Setting id Field from API
          userData.id = response.data.id;
          // Unnecessary Field
          delete response.data._id;
          return userData;
        })
        .catch(e => {
          return e;
        });
    },
    query({}, { endpoint, params }) {
      return securedAxios
        .get(endpoint, { params: params })
        .then(response => {
          let userData = {};
          userData = {
            ...response.data
          };
          // Setting id Field from API
          userData.id = response.data.id;
          // Unnecessary Field
          delete response.data._id;
          return userData;
        })
        .catch(e => {
          return e;
        });
    },
    // endpoint : String => /clients/, key : client (for sending Object key), userData : Object => Whole userData will save
    insert(__, { endpoint, key, userData, extraPayload = {} }) {
      const insertObject = {
        [key]: userData,
        ...extraPayload
      };

      return securedAxios
        .post(endpoint, insertObject)
        .then((response) => {
          return response;
        })
        .catch(e => Promise.reject(e));
    },
    // endpoint : String => /clients/, id : String => 5cd8284a30b7210004a0b32f, key : client (for sending Object key), userData : Object => Whole userData will save
    update({}, { endpoint, id, key, userData }) {
      if (endpoint?.charAt(endpoint?.length - 1) !== "/") endpoint += "/";
      let updateObject = {};
      updateObject[key] = userData;
      return securedAxios
        .patch(`${endpoint}${id}`, JSON.stringify(updateObject))
        .then(response => {
          return response;
        })
        .catch(e => {
          return Promise.reject(e);
        });
    },
    // Activate or Deactivate Toggle
    // endpoint : String => /clients/, id : String => 5cd8284a30b7210004a0b32f, items : Object => Whole userData from comes from ListPage for Marking Active or Passive on list item
    // type : String => /activate, /deactivate For making endpoint
    // toggleStatus : Boolean => true/false For Marking on list page..
    activeToggle({}, { adapter = securedAxios, endpoint, id, items, type, toggleStatus, force, method = "POST" }) {
      if (endpoint?.charAt(endpoint?.length - 1) !== "/") endpoint += "/";
      return adapter({
        method,
        url: endpoint + id + type,
        data: { force: force }
      })
        .then(response => {
          let itemIndex = items.findIndex(item => {
            return item.id == id;
          });
          if (itemIndex > -1) {
            items[itemIndex].active = toggleStatus;
          }
          return {
            type: "success"
          };
        })
        .catch(error => {
          return {
            type: "error",
            response: error.response
          };
        });
    }
  },
  modules: {
    menu,
    enums,
    user,
    webmasters,
    clients,
    client_settings,
    users,
    countries,
    locations,
    custom_field_types,
    custom_field_groups,
    custom_fields,
    custom_files,
    custom_calculators,
    custom_form_models,
    contacts,
    accounting_invoices,
    accounting_invoice,
    accounting_payments,
    accounting_taxes,
    accounting_credits,
    accounting_commissions,
    accounting_commission_payments,
    accounting_commission_rules,
    accounting_currency_rates,
    inventory_price_types,
    inventory_product_var_option_types,
    inventory_product_types,
    inventory_products,
    inventory_product,
    inventory_products_order_advisors,
    inventory_product_re_packs,
    inventory_product_pictures,
    inventory_product_categories,
    lamcom_file_groups,
    lamcom_layouts,
    lamcom_files,
    taxons,
    templates,
    logistics,
    dashboards,
    widgets,
    hive_jobs,
    hive_jobs_services,
    hive_job_templates,
    hive_projects,
    hive_work_orders,
    hive_b2b_users,
    hive_prepress_files,
    hive_prepress_layouts,
    karavan_products,
    karavan_stores,
    karavan_shipment_services,
    karavan_orders,
    client_custom_lamcom_hive_projects,
    client_custom_lamcom_hive_layouts,
    client_custom_lamcom_hive_steps,
    client_custom_lamcom_hive_work_orders_dashboard_widget,
    client_custom_lamcom_hive_work_orders,
    client_custom_lamcom_hive_dashboards_widgets,
    client_custom_lamcom_accounting,
    client_custom_lamcom_hive_jobs,
    client_custom_lamcom_reports,
    pigeon_notifications,
    tpa,
    marketing_rewards,
    i18n_docs,
    MaestroEnums,
    maestro,
    job,
    printServerProcesses
  },
  plugins: [
    createPersistedState({
      // paths: ["inventory_products.orderPriceTypes", "inventory_products.orderPageLoading", "inventory_products.orderTemplates","custom_fields.customFieldItems"],
      paths: ["custom_fields.customFieldItems"],
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ]
});
